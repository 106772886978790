<template>
  <div class="join_box">
    <div class="join_img">
      <video
        autoplay="autoplay"
        muted
        loop
        :src="bannerData.bannerUrl"
        v-if="bannerData.type == 2"
      ></video>
      <el-image
        class="joinimg"
        :src="bannerData.bannerUrl"
        fit="cover"
        v-if="bannerData.type == 1"
      ></el-image>
    </div>
    <!-- <div class="tz">
      <div class="tz_title" v-animation:home="'a-fadeinT'">投资前景</div>
      <div class="ms" v-animation:home="'a-fadeinT'">
        充电宝的市场前景与投资潜力：手机已经成为人们的一个“器官”，人们每天在手机上花费的时间越来越多，导致手机
        耗电量增大，充电需求非常强烈，据测算每天为手机充电- -次的用户占39.4%，
        每天充电 2-
        -3次的占比41.6%，每天充电3次以上的占比为12.8%，但大多数出门在外的人不会随身携带充电宝。
        共享充电宝主要投放在商场、餐厅、机场、医院及休闲娱乐等室内场景，对网络的深度覆盖要求较高。2019年之前，行业普遍采用Cat.4模组联网通信,但是Cat.4模组对于价格敏感的共享充电宝行业仍然成本较高，而Cat.1模组价格相对于Cat.4模组有明显优势。
        此外， 共享充电宝还有异地归还的业务模式，而LTE
        Cat.1可以很好的满足此模式对通信移动性的要求。
        共享充电市场空间巨大，全年用户规模已达到1.5亿人次。目前，基于LTE Cat.
        1的共享充电宝已在深圳、广州等一二
        线城市全面覆盖，广泛应用到商场、餐厅、机场及休闲娱乐等主流消费场景。
        无论在繁华的北上广深，还是三四线小县城，随处可见共享充电宝的身影。
      </div>
    </div> -->
    <div class="join_box1">
      <div class="join_title">如何成为黑蜻蜓的合作伙伴</div>
      <div class="hzimgbox">
        <!-- <el-image
          class="hzhbImage1"
          :src="require('../assets/hzhb.png')"
          fit="contain"
        /> -->
        <div class="hzimg-item1">
          <div class="img-box">
            <img src="../assets/y01.png" />
          </div>
          <div>提交申请</div>
        </div>
        <div class="hzimg-item2">>></div>
        <div class="hzimg-item1">
          <div class="img-box">
            <img src="../assets/y02.png" />
          </div>
          <div>电话商谈</div>
        </div>
        <div class="hzimg-item2">>></div>
        <div class="hzimg-item1">
          <div class="img-box">
            <img src="../assets/y03.png" />
          </div>
          <div>见面详谈</div>
        </div>
        <div class="hzimg-item2">>></div>
        <div class="hzimg-item1">
          <div class="img-box">
            <img src="../assets/y04.png" />
          </div>
          <div>签约代理</div>
        </div>
      </div>
    </div>
    <div class="top4">
      <div class="top_img">
        <el-image
          class="top4_img"
          :src="require('../assets/join/join-01.jpg')"
          fit="cover"
        ></el-image>
      </div>
    </div>

    <div class="join_use">
      <div class="use_title" v-animation:home="'a-fadeinT'">使用场景</div>
      <div class="use_box">
        <div
          class="box_main"
          v-for="(item, index) in scenelist"
          :key="index"
          v-animation:home="'a-fadeinT'"
        >
          <div class="boxtop">
            <el-image class="boximg" :src="item.img" fit="cover"></el-image>
          </div>
          <div class="boxbottom">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="dl_case">
      <div class="case_title" v-animation:home="'a-fadeinT'">
        代理商收益案例
      </div>
      <div class="case_box" v-animation:home="'a-fadeinT'">
        <el-table
          id="table"
          :data="tableData"
          border
          size="mini"
          style="width: 100%"
          :header-cell-style="{}"
        >
          <el-table-column fixed prop="type" label="产品类型" align="center">
            <!-- <template slot-scope="scope">
              <el-image :src="scope.row.date" fit="cover"></el-image>
            </template> -->
          </el-table-column>
          <el-table-column prop="name" align="center" label="投放区域">
          </el-table-column>
          <el-table-column prop="province" align="center" label="机器数量">
          </el-table-column>
          <el-table-column prop="money" align="center" label="年收益(元)">
          </el-table-column>
          <el-table-column prop="address" align="center" label="回本周期(月)">
          </el-table-column>
          <el-table-column prop="zip" align="center" label="服务商年收益(元)">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="bz">
      <div class="bz_title" v-animation:home="'a-fadeinT'">
        2020黑蜻蜓充电加盟保障
      </div>
      <div class="bz_box" v-animation:home="'a-fadeinT'">
        <div class="bz-item">
          <img src="../assets/join/z01.png" />
          <div>设备可回收</div>
        </div>
        <div class="bz-item">
          <img src="../assets/join/z02.png" />
          <div>免费加盟</div>
        </div>
        <div class="bz-item">
          <img src="../assets/join/z03.png" />
          <div>免提现手续费</div>
        </div>
        <div class="bz-item">
          <img src="../assets/join/z04.png" />
          <div>可免息分期购机</div>
        </div>
      </div>
    </div>
    <div class="button_gg">
      <HelloWorld></HelloWorld>
    </div>
  </div>
</template>
<script>
import HelloWorld from "../components/HelloWorld";
import { banner } from "@/api/api_list";
export default {
  data() {
    return {
      scenelist: [
        { img: require("../assets/zsjm/jb.jpg"), title: "酒吧/KTV/轻吧/影院" },
        { img: require("../assets/zsjm/jc.jpg"), title: "候车厅/候机楼/大巴" },
        { img: require("../assets/zsjm/kft.jpg"), title: "咖啡店/饮品店" },
        { img: require("../assets/zsjm/ct.jpg"), title: "餐厅/商场" },
        { img: require("../assets/zsjm/jsf.jpg"), title: "健身房/洗浴中心" },
        { img: require("../assets/zsjm/jd.jpg"), title: "酒店/会所" },
        { img: require("../assets/zsjm/sc.jpg"), title: "商场" },
        { img: require("../assets/zsjm/sc1.jpg"), title: "超市" },
      ],
      tableData: [
        {
          date: "https://wallpaperm.cmcm.com/live/preview_img_raw/1e1d7352f1c97f19af76a3fe5766ac08_preview_raw.jpg",
          name: "超市",
          province: "1",
          money: "9600",
          address: "1",
          zip: 8640,
          type: "桌式6宝",
        },
        {
          date: "https://wallpaperm.cmcm.com/live/preview_img_raw/1e1d7352f1c97f19af76a3fe5766ac08_preview_raw.jpg",
          name: "台球吧",
          province: "1",
          money: "28800",
          address: "0.5",
          zip: 25920,
          type: "桌式12宝",
        },
        {
          date: "https://wallpaperm.cmcm.com/live/preview_img_raw/1e1d7352f1c97f19af76a3fe5766ac08_preview_raw.jpg",
          name: "餐饮",
          province: "1",
          money: "60000",
          address: "1",
          zip: 54000,
          type: "立式24宝",
        },
        {
          date: "https://wallpaperm.cmcm.com/live/preview_img_raw/1e1d7352f1c97f19af76a3fe5766ac08_preview_raw.jpg",
          name: "酒吧",
          province: "1",
          money: "420000",
          address: "0.5",
          zip: 378000,
          type: "立式48宝",
        },
      ],
      bannerData: [],
    };
  },
  components: {
    HelloWorld,
  },
  created() {
    banner({ location: 3 }).then((res) => {
      if (res.code == 200) {
        this.bannerData = res.data;
      }
    });
  },
  mounted() {
    window.addEventListener("scroll", () => {
      //隐藏首页title
      var H = document.documentElement.scrollTop || document.body.scrollTop;
      if (H > 40) {
        console.log("hhhh");
        let el = document.getElementById("nav-top"),
          classArr = el.className.split(" ") || [];
        if (classArr.indexOf("show_top") == -1) {
          el.className = `${el.className} ${"show_top"}`;
        }
      } else {
        let el = document.getElementById("nav-top"),
          classArr = el.className.split(" ") || [];
        if (classArr.indexOf("show_top") !== -1) {
          classArr.splice(classArr.indexOf("show_top"), 1);
          el.className = `${classArr.join(" ")}`;
        }
      }
    });
  },
  methods: {},
};
</script>
<style lang="scss" >
// .el-table__header{
//   background: chartreuse;
// }
// .el-table__body tr{
//   background: #ccc;
// }
.el-table__body tr:hover > td {
  background-color: black !important;
}
.join_box {
  width: 100%;
  height: 100%;
  background: white;
  .el-table tr,
  .el-table th {
    // background-color: black;
  }
  .join_img {
    width: 100%;
    height: 500px;
    .joinimg {
      width: 100%;
      height: 100%;
    }
  }
  .join_box1 {
    width: 100%;
    // height: 300px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    .join_title {
      width: 100%;
      height: 50px;
      font-size: 26px;
      font-weight: bold;
      color: black;
      line-height: 50px;
      text-align: center;
      padding-top: 50px;
    }
    .hzimgbox {
      margin-top: 5vw;
      margin-bottom: 5vw;
      width: 80vw;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      .hzimg-item2 {
        height: 8vw;
        line-height: 8vw;
        font-size: 1.5vw;
      }

      .hzimg-item1 {
        height: 8vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;

        font-size: 1.2vw;

        .img-box {
          width: 5vw;
          height: 5vw;
          border-radius: 50px;
          border: 1px solid black;

          margin-bottom: 0.8vw;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            height: 2vw;
          }
        }
      }

      .hzhbImage1 {
        width: 100%;
        height: 100%;
      }
    }
  }
  .join_use {
    width: 100%;
    background: white;
    color: black;
    .use_title {
      width: 100%;
      height: 50px;
      font-size: 26px;
      font-weight: bold;
      line-height: 50px;
      text-align: center;
      padding-top: 40px;
      margin-bottom: 50px;
    }
    .use_box {
      margin: 0 auto;
      width: 80%;
      /* background: yellow; */
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .box_main {
        width: 23%;
        height: 150px;
        margin-bottom: 20px;
        .boxtop {
          width: 100%;
          height: 120px;
          overflow: hidden;
          /* background:blue; */
          .boximg {
            width: 100%;
            height: 100%;
            transition: all 0.6s;
          }
          .boximg:hover {
            transform: scale(1.1);
          }
        }
        .boxbottom {
          width: 100%;
          height: 30px;
          /* background:green; */
          line-height: 30px;
          font-size: 14px;
          text-indent: 1em;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .dl_case {
    width: 100%;
    background: white;

    .case_title {
      width: 100%;
      height: 50px;
      font-size: 26px;
      font-weight: bold;
      color: black;
      line-height: 50px;
      text-align: center;
      padding-top: 50px;
      margin-bottom: 50px;
    }
    .case_box {
      margin: 0 auto;
      width: 80%;
      /* background: yellow; */
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  .bz {
    width: 100%;
    background: white;
    .bz_title {
      width: 100%;
      height: 50px;
      font-size: 26px;
      font-weight: bold;
      color: black;
      line-height: 50px;
      text-align: center;
      padding-top: 50px;
      /* padding-top: 20px; */
      margin-bottom: 50px;
    }
    .bz_box {
      margin: 0 auto;
      width: 80%;
      /* background: yellow; */
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .bz-item {
        width: 20%;
        height: 6vw;
        font-size: 1.2vw;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }

      img {
        height: 3vw;
      }
    }
  }
  .tz {
    width: 100%;
    background: white;
    .tz_title {
      width: 100%;
      height: 50px;
      font-size: 26px;
      font-weight: bold;
      color: #fff;
      line-height: 50px;
      text-align: center;
      padding-top: 50px;
      /* padding-top: 20px; */
      /* margin-bottom: 50px; */
    }
    .ms {
      padding-top: 50px;
      width: 80%;
      font-size: 16px;
      color: black;
      margin: 0 auto;
      text-indent: 2em;
      line-height: 20px;
    }
  }
  .button_gg {
    width: 100%;
    height: 100%;
    background: white;
  }

  .top4 {
    padding-top: 10px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    background-color: white;
    .top_img {
      width: 50%;
      height: 550px;
      overflow: hidden;
      .top4_img {
        width: 100%;
        height: 100%;
        transition: all 0.6s;
      }
    }
  }
}
</style>
